import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "clutch/src/Select/Select.js";
import { TextInput } from "clutch/src/TextInput/TextInput.js";
import type { TFunction } from "i18next";

import { appURLs } from "@/app/app-urls.mjs";
import { getWikiLink } from "@/game-palworld/components/wiki.style.jsx";
import type { WikiColumn } from "@/game-palworld/components/WikiList.jsx";
import {
  getFilterByLabel,
  useWikiListFilter,
  WikiColumnName,
  WikiList,
} from "@/game-palworld/components/WikiList.jsx";
import { BUILDING_SUB_CATEGORIES } from "@/game-palworld/constants/buildings.mjs";
import type {
  Building,
  Technology,
} from "@/game-palworld/models/model-wiki.mjs";
import { getWikiData } from "@/game-palworld/utils/use-wiki-data.mjs";
import SearchIcon from "@/inline-assets/search-icon.svg";
import type { Cell } from "@/shared/DataTable.jsx";
import DataTable from "@/shared/DataTable.jsx";
import SortOrder from "@/shared/SortOrder";
import { useQuery } from "@/util/router-hooks.mjs";

const subCategoryOptions = Object.entries(BUILDING_SUB_CATEGORIES).reduce(
  (acc, [k, v]) => {
    acc.push({ value: k, text: v.label, image: "" });
    return acc;
  },
  [
    {
      value: "",
      text: ["palworld:category.all", "All Categories"] as Translation,
      image: "",
    },
  ],
);

type BuildingSortOption =
  | {
      text: Translation;
      value: string;
      sortBy: keyof Building;
      techSortBy?: never;
      fallback?: never;
    }
  | {
      text: Translation;
      value: string;
      sortBy?: never;
      techSortBy: keyof Technology;
      fallback: string | number | null;
    };

const getFilterByCategory =
  (categoryFilter: string) =>
  <T extends { subCategory: string }>(_t: TFunction, i: T) =>
    i.subCategory === categoryFilter;

const getItemSortValue = (
  sortOpt: BuildingSortOption,
  a: Building,
  b: Building,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): [any, any] => {
  let aV;
  let bV;
  if (sortOpt.techSortBy) {
    aV =
      getWikiData().buildingTech?.[a.techRequirementId]?.[sortOpt.techSortBy];
    bV =
      getWikiData().buildingTech?.[b.techRequirementId]?.[sortOpt.techSortBy];
  } else {
    aV = a[sortOpt.sortBy];
    bV = b[sortOpt.sortBy];
  }

  return [aV ?? sortOpt.fallback, bV ?? sortOpt.fallback];
};

const cols: WikiColumn[] = [
  {
    display: ["palworld:buildingName", "Building Name"],
    align: "left",
    primary: true,
  },
  {
    display: ["palworld:category", "Category"],
  },
  {
    display: ["palworld:levelReq", "Level Req."],
    isStat: true,
    align: "right",
  },
];

const getRows = (items: Building[], t: TFunction): Cell[][] =>
  items.map(({ id, imageUri, label, subCategory, techRequirementId }) => [
    {
      display: (
        <WikiColumnName
          imgSrc={`${appURLs.CDN_PLAIN}/${imageUri}`}
          name={t(...label)}
        />
      ),
      value: null,
      link: getWikiLink(`buildings:${id}`),
    },
    {
      display: t(...BUILDING_SUB_CATEGORIES[subCategory].label),
      value: null,
    },
    {
      value:
        getWikiData().buildingTech?.[techRequirementId]?.levelRequirement ?? 1,
      display:
        getWikiData().buildingTech?.[techRequirementId]?.levelRequirement ?? 1,
    },
  ]);

const sortOptions: BuildingSortOption[] = [
  {
    value: "name",
    text: ["palworld:sort.name", "Name"],
    sortBy: "label",
  },
  {
    value: "category",
    text: ["palworld:sort.subCategory", "Category"],
    sortBy: "subCategory",
  },
  {
    value: "levelReq",
    text: ["palworld:sort.levelReq", "Level Requirement"],
    techSortBy: "levelRequirement",
    fallback: 1,
  },
];

export function BuildingList({ buildings }: { buildings: Building[] }) {
  const { t } = useTranslation();

  const [nameFilter, setNameFilter] = useQuery<string>("name", "");
  const [categoryFilter, setCategoryFilter] = useQuery<string>("category", "");

  const filters = useMemo(() => {
    const filters = [];
    if (nameFilter) filters.push(getFilterByLabel(nameFilter));
    if (categoryFilter) filters.push(getFilterByCategory(categoryFilter));

    return filters.length ? filters : null;
  }, [categoryFilter, nameFilter]);

  const {
    allCols,
    rows,
    sortOption: [sortMode, setSortMode],
    sortToggle: [sortOrder, setSortOrder],
  } = useWikiListFilter({
    t,
    cols,
    getSortValue: getItemSortValue,
    filters,
    getRows,
    items: buildings,
    sortOptions,
  });

  const filteredCategoryOptions = useMemo(
    () =>
      buildings
        ? subCategoryOptions.filter(
            (opt) =>
              buildings.find((it) => it.subCategory === opt.value) ||
              !opt.value,
          )
        : subCategoryOptions,
    [buildings],
  );

  return (
    <WikiList>
      <div className="search-container">
        <TextInput
          placeholder={t("common:search", "Search")}
          onChange={(e) => setNameFilter(e.target.value)}
          defaultValue={nameFilter}
          Icon={SearchIcon}
        />
        {filteredCategoryOptions.length > 2 && (
          <Select
            options={filteredCategoryOptions}
            selected={categoryFilter || ""}
            onChange={(v) => setCategoryFilter(v || undefined)}
          />
        )}
        <div className="sort-container">
          <Select
            options={sortOptions}
            selected={sortMode || ""}
            onChange={(v) => setSortMode(v || undefined)}
          />
          <SortOrder sortOrder={sortOrder} setSortOrder={setSortOrder} />
        </div>
      </div>
      <DataTable
        className="wiki-table"
        loadingRows={25}
        sortable={false}
        cols={allCols}
        rows={rows}
      />
    </WikiList>
  );
}
